import { CheckCircleIcon, MicrophoneIcon, ShareIcon, PaperAirplaneIcon, XMarkIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { v4 as uuidv4 } from 'uuid';
import {processRecording, generateSoapNotes, generateSOAPFollowup, fetchTemplates } from './ServerActions'
import { downloadSupabaseFileAsString, updateToSupabase, uploadRecordingToSupabase, uploadToSupabase } from './supabase/client'
import { useAuth } from './auth/AuthProvider'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { ArrowLongRightIcon, ArrowRightCircleIcon, ClipboardDocumentIcon, ClipboardIcon, CloudArrowUpIcon, ExclamationTriangleIcon, PlusCircleIcon, SparklesIcon, StopCircleIcon } from '@heroicons/react/24/solid'
import { Spinner } from './utils/Spinner';
import { TemplateObject } from './utils/TemplateObject';

// Define the types for your props
interface ScribeProps {
    
}

interface IMicrophone {
    deviceId: string;
    label: string;
}

const HighlightText = (text:string) => {
    const textWithLineBreaks = SpacingText(text)
    // Hihglight bracketed areas
    const regex = /\[(.*?)\]/g;
    const  highlightedText = textWithLineBreaks.replace(regex, '[<span style="background-color: yellow;">$1</span>]');
    return highlightedText
}

const SpacingText = (text:string) => {
    // New lines to HTML
    const textWithLineBreaks = text.replace(/\n/g, '<br>');
    return textWithLineBreaks
}

const formatTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = secs.toString().padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

function checkMicrophonePermissions(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream: MediaStream) => {
                // Microphone access was granted
                stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
                resolve(true);
            })
            .catch((err: any) => {
                // Microphone access was denied or an error occurred
                reject(false);
            });
    });
}

function isWakeLockSupported() {
    return "wakeLock" in navigator;
}

export default function Scribe(props:ScribeProps) {
    // Session controllers
    const [sessionId, setSessionId] = useState<string>(uuidv4())
    const [name, setName] = useState<string>('')
    const [share, setShare] = useState<boolean>(false)
    const [state, setState] = useState<string>("Recording")
    const [loading, setLoading] = useState<string|undefined>(undefined)
    const [notesLoading, setNotesLoading] = useState<string|undefined>(undefined)
    const { session } = useAuth();
    const [seconds, setSeconds] = useState<number>(0);
    const [timerActive, setTimeActive] = useState<boolean>(false);
    const [error, setError] = useState<string|undefined>(undefined)
    const [errorTimer, setErrorTimer] = useState<number>(0);
    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const [copyTimer, setCopyTimer] = useState<number>(0);
    const wakeLockSupported = isWakeLockSupported()
    const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
    const [wakeLockActive, setWakeLockActive] = useState<boolean>(false);

    // Template Variables
    const preBuiltTemplate : Array<TemplateObject> = [    {
        id: "summary_template",
        name: "Summary Notes",
        owner: "Neum",
        organization:"Neum",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now()
    },
    {
        id: "support_template",
        name: "Support Notes",
        owner: "Neum",
        organization:"Neum",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now()
    },
    {
        id: "sales_template",
        name: "Sales Notes",
        owner: "Neum",
        organization:"Neum",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now()
    }]
    const [loadingTemplates , setLoadingTemplates] = useState<boolean>(false)
    const [templates, setTemplates] = useState<TemplateObject[]>()
    const [template, setTemplate] = useState<TemplateObject>(preBuiltTemplate[0])

    // Transcript Variables
    const [transcript, setTranscript] = useState<string|undefined>(undefined)
    const [transcriptChanged, setTranscriptChanged] = useState<boolean>(false)

    // Notes variables
    const [notes, setNotes] = useState<string|undefined>(undefined)
    const [notesChanged, setNotesChanged] = useState<boolean>(false)
    const [inputValueSOAP, setInputValueSOAP] = useState<string>('');
    const [displayNotes, setDisplayNotes] = useState('');

    // Scoring variables
    const [openScore, setOpenScore] = useState<boolean>(false)
    const [score, setScore] = useState<number|undefined>(undefined)

    // Manual Notes variables
    const [manualNotes, setManualNotes] = useState<string|undefined>(undefined)
    const [manualNotesChanged, setManualNotesChanged] = useState<boolean>(false)

    // Recording variables
    const [microphones, setMicrophones] = useState<IMicrophone[]>([]);
    const [selectedMic, setSelectedMic] = useState<IMicrophone>({deviceId: "", label: ""});
    const [microphonePermission, setMicrophonePermission] = useState<boolean | null>(null); 
    const [microphonePermissionStatus, setMicrophonePermissionStatus] = useState<string|undefined>(undefined)
    const [consent, setConsent] = useState(false);
    const consentRef = useRef(consent);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | undefined>(undefined)
    const [mediaStream, setMediaStream] = useState<MediaStream | undefined>(undefined);
    const [recordingPaused, setRecordingPaused] = useState<boolean>(false)
    const audioChunksRef = useRef<Array<Blob>>([]);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (copySuccess) {
            setCopyTimer(0); // Reset progress
            timer = setTimeout(() => {
                setCopySuccess(false);
            }, 1000); // 5000ms = 5 seconds
    
            // Update progress every 50ms
            interval = setInterval(() => {
                setCopyTimer((oldProgress) => {
                    console.log(oldProgress)
                    if (oldProgress < 100) {
                        return oldProgress + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 30);
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [copySuccess]);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (error) {
            setErrorTimer(0); // Reset progress
            timer = setTimeout(() => {
                setError(undefined);
            }, 3000); // 5000ms = 5 seconds
    
            // Update progress every 50ms
            interval = setInterval(() => {
                setErrorTimer((oldProgress) => {
                    console.log(oldProgress)
                    if (oldProgress < 100) {
                        return oldProgress + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 30);
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [error]);

    useEffect(() => {
        consentRef.current = consent;
    }, [consent]);

    useEffect(() => {
        const checkMic = async () => {
            try {
                setMicrophonePermissionStatus("Checking permissions...")
                const permission = await checkMicrophonePermissions();
                setMicrophonePermission(permission);
                setMicrophonePermissionStatus("Getting microphones...")
                let devices = await navigator.mediaDevices.enumerateDevices()
                const mics: IMicrophone[] = devices
                    .filter(device => device.kind === 'audioinput')
                    .map(device => ({
                        deviceId: device.deviceId,
                        label: device.label || `Microphone ${device.deviceId}`
                    }));
                setMicrophonePermissionStatus(undefined)
                setMicrophones(mics);
                if (mics.length > 0) {
                    setSelectedMic(mics[0]);
                }
            } catch (error) {
                setMicrophonePermission(false);
                setMicrophonePermissionStatus(undefined)
            }
        }
        const getTemplates = async () => {
            if(session){
                setLoadingTemplates(true)
                let temp_templates : TemplateObject[] = await fetchTemplates(session.access_token)
                setTemplates([...preBuiltTemplate, ...temp_templates])
                setLoadingTemplates(false)
            }
        }
        getTemplates()
        checkMic()
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
    
        if (timerActive) {
          interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
          }, 1000);
        } else if (interval) {
          clearInterval(interval);
        }
    
        return () => {
          if (interval) clearInterval(interval);
        };
    }, [timerActive, seconds]);

    const toggleWakeLock = async () => {
        if(wakeLockSupported){
            if (wakeLockActive) {
                // Release the wake lock if it's active
                try {
                    await wakeLock?.release();
                    setWakeLockActive(false);
                    setWakeLock(null);
                    console.log('Screen wake lock released');
                } catch (err) {
                    console.error('Failed to release wake lock:', err);
                }
            } else {
                // Request the wake lock if it's not active
                try {
                    const newWakeLock = await navigator.wakeLock.request('screen');
                    setWakeLock(newWakeLock);
                    setWakeLockActive(true);
                    console.log('Screen wake lock is active');
                } catch (err) {
                    console.error('Failed to acquire wake lock:', err);
                }
            }
        }
    };

    const copyToClipboard = async (text: string): Promise<void> => {
        try {
          await navigator.clipboard.writeText(text);
          setCopySuccess(true);
        } catch (err) {
          setCopySuccess(false);
        }
    };

    async function downloadTranscript() : Promise<void> {
        setTranscript(await downloadSupabaseFileAsString("transcripts", session?.user.email + "/" + sessionId + "_transcript.txt"))
    }

    async function processContext(): Promise<void> {
        let processingRecording = false
        if(audioChunksRef.current.length == 0 && !manualNotes){
            setError("Record audio or add notes to continue.")
            return
        }
        if(manualNotes){
            setLoading("Saving your notes 📒...")
            const blob = new Blob([manualNotes], {type: 'text/plain'})
            const file = new File([blob], `${sessionId}_manualnotes.txt`, {type: 'text/plain'})
            await uploadToSupabase(file,`${session?.user.email}/${sessionId}_manualnotes.txt`, 'manual_notes')
        }
        if(audioChunksRef.current.length > 0 && consentRef.current && session){
            processingRecording = true
            let recordingBlob = new Blob(audioChunksRef.current, { type: 'audio/mpeg' });
            audioChunksRef.current = [];
            const recordingFile = new FormData();
            recordingFile.append("file", recordingBlob, sessionId + ".mp3");
            setLoading("Uploading recording 💿...")
            const file = recordingFile.get("file")
            await uploadRecordingToSupabase(file, session?.user.email + "/" + sessionId + ".mp3", "recordings")
            setLoading("Transcribing recording 🤖...")
            await processRecording(sessionId, name, session.access_token,  manualNotes != undefined, consent)
            setLoading("Fetching the transcript 📄...")
            await downloadTranscript()
            setState("Transcript")
            setLoading(undefined)
        }
        if(manualNotes && audioChunksRef.current.length == 0 && !processingRecording){
            setState("Transcript")
            setLoading(undefined)
        }
    }

    async function processTranscript(): Promise<void> {
        //Generate notes by calling the backend
        if(manualNotes && manualNotesChanged){
            setLoading("Updating your notes 📒...")
            const blob = new Blob([manualNotes], {type: 'text/plain'})
            const file = new File([blob], `${sessionId}_manualnotes.txt`, {type: 'text/plain'})
            await updateToSupabase(file,`${sessionId}_manualnotes.txt`, 'manual_notes')
        }
        setLoading("Generating notes 🤖")
        if (session)
        {
            await generateSoapNotes(sessionId, name, template.organization + "/" + template.id, transcript != undefined,  manualNotes != undefined, session.access_token)
            //Download notes from supabase
            let temp_notes = await downloadSupabaseFileAsString("notes", session?.user.email + "/" + sessionId +"_notes.txt")
            setNotes(temp_notes)
            setDisplayNotes(HighlightText(temp_notes))
            setState("Notes")
            setLoading(undefined)
        }
    }

    function processShare(): void {
        setShare(true)
    }

    function sendEmail(): void {
        alert("sent email")
        setShare(false)
    }

    function openScorePanel(): void {
        setOpenScore(true)
    }

    function cancelRecording(): void {
        setConsent(false)
        mediaRecorder?.stop() //Stop is handled by subscribed method in handleRecord
        // Stop the media stream tracks
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => track.stop());
            setMediaStream(undefined);
        }
    }

    async function handleRecord(): Promise<void> {
        if (mediaRecorder && mediaRecorder.state === "recording") {
            mediaRecorder.stop();
            await toggleWakeLock()
            // Stop the media stream tracks
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
                setMediaStream(undefined);
            }
        } else {
            navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedMic.deviceId ? { exact: selectedMic.deviceId } : undefined }
            })
                .then(async stream => {
                    setMediaStream(stream); // Store the stream reference
                    let mr = new MediaRecorder(stream)
                    setMediaRecorder(mr);
                    mr.ondataavailable = event => {
                        console.log("pushing chunks" + audioChunksRef.current.length)
                        audioChunksRef.current.push(event.data);
                    };
                    mr.onstop = async () => {
                        setRecordingPaused(true)
                        setTimeActive(false)
                    };
                    mr.start(1000);
                    await toggleWakeLock()
                    setRecordingPaused(false)
                    setTimeActive(true)
                });
        }
    }

    async function handleSoapAI(): Promise<void> {
        if(session?.access_token)
        {
            setNotesLoading("Updating your notes ✨...")
            setInputValueSOAP('')
            await generateSOAPFollowup(sessionId, inputValueSOAP, session?.access_token)
            let temp_notes = await downloadSupabaseFileAsString("notes",  session?.user.email + "/" + sessionId + "_notes.txt" + "?version=" + uuidv4())
            setNotes(temp_notes)
            setDisplayNotes(HighlightText(temp_notes))
            setNotesLoading(undefined)
        }
    }

    function handleSoapInput(event: ChangeEvent<HTMLInputElement>): void {
        setInputValueSOAP(event.target.value)
    }

    async function saveNotes(): Promise<void> {
        if(notes)
        {
            setNotesLoading("Saving your notes 💾...")
            const blob = new Blob([notes], {type: 'text/plain'})
            const file = new File([blob], `${sessionId}_notes.txt`, {type: 'text/plain'})
            await updateToSupabase(file,`${sessionId}_notes.txt`, 'notes')
            setNotesLoading(undefined)
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
            if (loading) {
                e.preventDefault();
                e.returnValue = 'We are processing your request. Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return (): void => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [loading]);

    return(<>
        <div className="border-b border-gray-400 pb-5 mb-5 py-4 sm:py-10">
            <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                Scribe
            </h2>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                Neum Scribe listens to your consultation and automatically generates a transcript <u>and</u> notes that you can use for your records. If you would like to customize the notes template used head over to the <a href="/templates" className="underline text-gray-400">templates section</a>.
            </p>
        </div>
        {loading && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-4'>
            {loading}
            <Spinner />
        </div>}
        {state == "Recording" && !loading && <div>
            <div className='flex sm:flex-row flex-col w-full gap-x-4 gap-y-4'>
                <div className="relative grow">
                    <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
                    >
                        Session Identifier
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-full h-12 rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="Call with X or 123-2323-45445"
                        onChange={(event) => setName(event.target.value)}
                    />
                </div>
                {templates && <div className="shrink-0 sm:w-60 w-full h-12 relative">
                    <label
                        htmlFor="name"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                    >
                        Template
                    </label>
                    <Listbox value={template} onChange={setTemplate} defaultValue={preBuiltTemplate[0]}>
                        <div className="relative z-[0]">
                        <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm h-12">
                            <span className="block truncate">{template?.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-gray-300 focus:outline-none sm:text-sm cursor-pointer z-[999999]">
                            {templates.map((template, templateIdx) => (
                                <Listbox.Option
                                key={templateIdx}
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 cursor-pointer ${
                                    active ? 'bg-accent text-main-text-darker' : 'text-main-text-darker'
                                    }`
                                }
                                value={template}
                                >
                                {({ selected }) => (
                                    <>
                                    <span
                                        className={`block truncate ${
                                        selected ? 'font-medium' : 'font-normal'
                                        }`}
                                    >
                                        {template.name}
                                    </span>
                                    {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                    </>
                                )}
                                </Listbox.Option>
                            ))}
                            </Listbox.Options>
                        </Transition>
                        </div>
                    </Listbox>
                </div>}
            </div>
            <div className='flex flex-col sm:flex-row pt-5 gap-x-8 gap-y-8'>
                <div className='shadow-md sm:w-[50%] w-full rounded-lg flex flex-col'>
                    <div className="border border-gray-400 rounded-t-lg bg-white px-4 py-5 sm:px-6 h-28">
                        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                            <div className="ml-4 mt-4">
                                <h3 className="text-base font-semibold leading-6 text-main-text-darker">Capture Recording</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Start recording. Once you are done, pause the recording and hit the "start processing" button to generate a transcript and notes.
                                </p>
                            </div>  
                        </div>
                    </div>
                    <div className='flex flex-grow p-12 border border-gray-300 rounded-b-lg justify-center'>
                        {!microphonePermission &&  <div>
                            {microphonePermissionStatus && <div className="flex flex-col justify-center items-center gap-y-6">
                                {microphonePermissionStatus}
                                <Spinner />
                            </div>}
                            {!microphonePermissionStatus && <div className="flex rounded-md bg-yellow-50 p-4">
                                <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                <h3 className="text-sm font-medium text-yellow-800">Microphone not allowed </h3>
                                <div className="mt-2 text-sm text-yellow-700">
                                    <p>
                                        Pleast allow microphone permissions for this application. If you blocked by mistake:<br/>
                                        <ul role="list" className="space-y-6 mt-2">
                                            <li className="relative flex gap-x-4">
                                                <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                                <div className="w-px bg-gray-200" />
                                                </div>
                                                <>
                                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                                    </div>
                                                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                        <span className="font-medium text-yellow-700">Go to your browser's privacy settings</span>
                                                    </p>
                                                </>
                                            </li>
                                            <li className="relative flex gap-x-4">
                                                <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                                <div className="w-px bg-gray-200" />
                                                </div>
                                                <>
                                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                                    </div>
                                                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                        <span className="font-medium text-yellow-700">Find the permissions section, and change the microphone access to 'Allow' for this site.</span>
                                                    </p>
                                                </>
                                            </li>
                                            <li className="relative flex gap-x-4">
                                                <div className='absolute left-0 top-0 flex w-6 justify-center -bottom-6'>
                                                <div className="w-px bg-gray-200 h-6" />
                                                </div>
                                                <>
                                                    <div className="relative flex h-6 w-6 flex-none items-center justify-center">
                                                        <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                                                    </div>
                                                    <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                                                        <span className="font-medium text-yellow-700">Reload the page to apply the changes."</span>
                                                    </p>
                                                </>
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                                </div>
                            </div>}
                        </div>}
                        {microphonePermission && <div className='flex flex-col gap-y-8 justify-center items-center'>
                            <button
                                type="button"
                                className={`rounded-full ${mediaRecorder && mediaRecorder.state === "recording" ? "bg-red-600 hover:bg-red-500":"bg-main-button hover:bg-main-button-hover"} px-4 py-2.5 text-md font-semibold text-main-button-text shadow-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-48 w-48 flex flex-col justify-center justify-items-center items-center gap-y-4`}
                                onClick={() => handleRecord()}
                            >
                                <span>{mediaRecorder && mediaRecorder.state === "recording" ? "Stop Recording" : (recordingPaused ? "Resume Recording" : "Start Recording")}</span> 
                                {mediaRecorder && mediaRecorder.state === "recording" ? <StopCircleIcon className='h-12'/> : <MicrophoneIcon className='h-12'/>}
                                {((mediaRecorder && mediaRecorder.state === "recording") || (recordingPaused)) && <p>{formatTime(seconds)}</p>}
                            </button>
                            <div className="shrink-0 sm:w-60 w-60 h-12 relative">
                                <label
                                    htmlFor="name"
                                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                >
                                    Select microphone
                                </label>
                                <Listbox value={selectedMic} onChange={setSelectedMic}>
                                    <div className="relative">
                                        <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm h-12">
                                            <span className="block truncate">{selectedMic.label}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                            </span>
                                        </Listbox.Button>
                                        <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                            {microphones.map((microphone) => (
                                                <Listbox.Option
                                                key={microphone.deviceId}
                                                className={({ active }) =>
                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                    active ? 'bg-accent  text-main-text-darker' : 'text-main-text-darker'
                                                    }`
                                                }
                                                value={microphone}
                                                >
                                                {({ selected }) => (
                                                    <>
                                                    <span
                                                        className={`block truncate ${
                                                        selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                    >
                                                        {microphone.label}
                                                    </span>
                                                    {selected ? (
                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent">
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                    </>
                                                )}
                                                </Listbox.Option>
                                            ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className='shadow-md sm:w-[50%] w-full rounded-lg'>
                    <div className="border rounded-t-lg border-gray-400 bg-white px-4 py-5 sm:px-6 h-28">
                        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                            <div className="ml-4 mt-4">
                                <h3 className="text-base font-semibold leading-6 text-main-text-darker">Additional Notes</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Any other information you want Neum AI to know?
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-grow py-6 px-8 border border-gray-300 rounded-b-lg justify-center'>
                        <div className="mt-2 w-full">
                            <ReactQuill
                                theme='snow'
                                placeholder='Add notes...'
                                className='block w-full h-80 rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                value={manualNotes ? SpacingText(manualNotes): ''}
                                onChange={(value) => setManualNotes(value)}
                                modules={{toolbar: false}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex w-full justify-end pt-8 pb-8 gap-x-8 items-center'>
                {!(mediaRecorder && mediaRecorder.state === "recording") && <div className='flex flex-row justify-center items-center gap-x-2'>
                    Done recording? <span className='font-semibold hidden sm:block'>Start processing!</span> <ArrowLongRightIcon className='h-6 w-6'/>
                </div>}
                {(mediaRecorder && mediaRecorder.state === "recording") && <div className='flex flex-row justify-center items-center gap-x-2'>
                    Stop recording to continue. <StopCircleIcon className='h-6 w-6 text-red-600'/>
                </div>}
                <button
                type="button"
                className={`inline-flex items-center gap-x-2 rounded-md ${!(mediaRecorder && mediaRecorder.state === "recording") ? "bg-main-button hover:bg-main-button-hover text-main-button-text" : "bg-main-button-disabled text-main-button-disabled-text"} px-3.5 py-2.5 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12`}
                onClick={processContext}
                disabled={mediaRecorder && mediaRecorder.state === "recording"}
                >
                    Start processing
                    <ArrowRightCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </button>
            </div>
        </div>}
        {state == "Transcript" && !loading && <div>
            {transcript && <div className="rounded-md bg-green-200 p-4">
                <div className="flex">
                    <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-900">Recording Processed</h3>
                        <div className="mt-2 text-sm text-green-800">
                            <p>Below you can see the generated transcript and provided notes. Add any more notes you had.</p>
                        </div>
                        </div>
                </div>
            </div>}
            <div className='flex flex-col sm:flex-row pt-5 gap-x-8 gap-y-8'>
                {transcript && <div className='shadow-md flex-grow rounded-lg flex flex-col sm:max-w-[50%]'>
                    <div className="border border-gray-400 rounded-t-lg bg-white px-4 py-5 sm:px-6">
                        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                            <div className="ml-4 mt-4">
                                <h3 className="text-base font-semibold leading-6 text-main-text-darker">Recording Transcript</h3>
                            </div>  
                        </div>
                    </div>
                    <div className='flex py-4 px-8 border border-gray-400 rounded-b-lg justify-center'>
                        <div className="mt-2 w-full">
                            <ReactQuill
                                theme='snow'
                                placeholder='Add notes...'
                                className='block w-full h-80 rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                value={SpacingText(transcript)}
                                readOnly
                                modules={{toolbar: false}}
                            />
                        </div>
                    </div>
                </div>}
                <div className='shadow-md flex-grow rounded-lg'>
                    <div className="border rounded-t-lg border-gray-400 bg-white px-4 py-5 sm:px-6">
                        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                            <div className="ml-4 mt-4">
                                <h3 className="text-base font-semibold leading-6 text-main-text-darker">Additional Notes</h3>
                            </div>
                        </div>
                    </div>
                    <div className='flex py-4 px-8 border border-gray-400 rounded-b-lg justify-center'>
                        <div className="mt-2 w-full">
                            <ReactQuill
                                theme='snow'
                                placeholder='Add notes...'
                                className='block w-full h-80 rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                value={manualNotes ? SpacingText(manualNotes) : ''}
                                onChange={(value) => {setManualNotes(value); setManualNotesChanged(true)}}
                                modules={{toolbar: false}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex w-full justify-end pt-8 pb-8'>
                <div className='flex gap-x-4'>
                    <a
                        href='/scribe'
                        className="inline-flex items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-2.5 text-sm font-semibold text-accent-button-text shadow-sm hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12"
                    >
                        New session
                        <PlusCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                    </a>
                    <button
                        type="button"
                        className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12"
                        onClick={processTranscript}
                    >
                        Generate notes
                        <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>}
        {state == "Notes" && !loading && <div>
            <div className='flex gap-x-4'>
                <div className="rounded-md bg-green-50 p-4 grow">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-green-900">Notes Ready</h3>
                            <div className="mt-2 text-sm text-green-800">
                                <p>Neum generated the notes based on your recording. Edit them directly or use the chat interface to have Neum AI help.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {score && <div className='flex flex-col bg-gray-100 rounded-md items-center w-48 sm:w-28 justify-center cursor-pointer shadow-sm hover:bg-gray-200' onClick={openScorePanel}>
                    <div className='text-gray-500'>Score</div>
                    <div className='text-2xl font-semibold'>90</div> 
                </div>}
            </div>
            <div className='flex flex-col sm:flex-row pt-5 gap-x-8 gap-y-8'>
                <div className='shadow-md flex-grow rounded-lg flex flex-col'>
                    <div className="border border-gray-400 rounded-t-lg bg-white px-4 py-3 sm:px-6">
                        <div className="-ml-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                            <div className="ml-4 mt-2">
                                <h3 className="text-base font-semibold leading-6 text-main-text-darker">Neum Notes</h3>
                            </div>  
                            {/* <div className='flex items-center justify-center align-center mt-2'>
                                <button
                                    type="button"
                                    className="inline-flex items-center gap-x-2 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-main-text shadow-sm hover:bg-main-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12"
                                    onClick={processShare}
                                >
                                    Share Notes
                                    <ShareIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                </button>
                            </div> */}
                            {notes && <div className='flex items-center justify-center align-center gap-x-3'>
                                <button
                                    type="button"
                                    className="inline-flex items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-1.5 text-sm font-semibold text-accent-button-text shadow-sm hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    onClick={() => saveNotes()}
                                >
                                    Save
                                    <CloudArrowUpIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-1.5 text-sm font-semibold text-accent-button-text shadow-sm hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    onClick={() => copyToClipboard(notes)}
                                >
                                    {copySuccess ? "Copied" : "Copy"}
                                    {copySuccess ? <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" /> :<ClipboardDocumentIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                                </button>
                            </div>}
                        </div>
                    </div>
                    <div className='flex py-4 px-8 border border-gray-400 rounded-b-lg justify-center'>
                        <div className="mt-2 w-full flex flex-col gap-y-4">
                            <div className="rounded-md bg-gray-50 p-4 grow">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <SparklesIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                        <span className="text-sm font-medium text-green-900">Neum AI: </span> Added some <span style={{backgroundColor:"yellow"}}>highlights</span> for information you might want to check.
                                    </div>
                                </div>
                            </div>
                            {!notesLoading && notes && 
                            <ReactQuill
                                theme='snow'
                                placeholder='Add notes...'
                                className='block w-full h-80 rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                value={displayNotes}
                                onChange={(value, d, s, editor) => {
                                    setDisplayNotes(value)
                                    setNotes(editor.getText())
                                }}
                                modules={{toolbar: false}}
                            />}
                            {notesLoading && <div className='flex flex-col align-center items-center mt-2 w-full h-80 justify-center gap-y-4'>
                                {notesLoading}
                                <svg aria-hidden="true" className="w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-accent" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="mt-6 flex rounded-md shadow-sm">
                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <input
                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 px-4 text-main-text-darker ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6 h-12"
                        placeholder="Neum AI add X and remove Y..."
                        onChange={handleSoapInput}
                        value={inputValueSOAP}
                    />
                    </div>
                    <button
                    type="button"
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-main-button-text ring-1 ring-inset ring-gray-400 bg-main-button hover:bg-main-button-hover"
                    onClick={handleSoapAI}
                    >
                        <SparklesIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        AI
                    </button>
                </div>
            </div>
            <div className='my-6 flex justify-end'>
                <a
                    href='/scribe'
                    className="inline-flex items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-2.5 text-sm font-semibold text-accent-button-text shadow-sm hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 h-12"
                >
                    New session
                    <PlusCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </a>
            </div>
        </div>}
        {share && <Transition.Root show={share} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShare}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <ShareIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-main-text-darker">
                      Share the notes
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Send the notes to an email of your choosing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                    <div>
                        <div className="mt-2 flex flex-col gap-y-4">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                className="block w-full rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                placeholder="you@example.com"
                                aria-describedby="email-description"
                            />
                            <button
                                type="button"
                                className="items-center gap-x-2 rounded-md bg-main px-3.5 py-2.5 text-sm font-semibold text-main-text shadow-sm hover:bg-main-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12"
                                onClick={sendEmail}
                            >
                                Send email
                            </button>
                        </div>
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
        </Transition.Root>}
        {openScore && <Transition.Root show={openScore} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpenScore}>
                <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                        <div className="flex h-full flex-col overflow-y-scroll bg-white pt-20 pb-6 sm:py-6 shadow-xl">
                            <div className="px-4 sm:px-6">
                            <div className="flex items-start justify-between">
                                <Dialog.Title className="text-base font-semibold leading-6 text-main-text-darker">
                                    Score Breakdown
                                </Dialog.Title>
                                <div className="ml-3 flex h-7 items-center">
                                <button
                                    type="button"
                                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    onClick={() => setOpenScore(false)}
                                >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                </div>
                            </div>
                            </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">{/* Your content */}</div>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </div>
            </Dialog>
        </Transition.Root>}
        {error && <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={error != undefined}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                        <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-main-text-darker">{error}</p>
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                        <button
                            type="button"
                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => {
                            setError(undefined)
                            }}
                        >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        </div>
                    </div>
                </div>
                <div className='w-full bg-gray-500'>
                        <div className={`bg-red-500 h-[5px]`} style={{width:`${errorTimer}%`}}/>
                </div>
            </div>
          </Transition>
        </div>
        </div>}
        {mediaRecorder && mediaRecorder.state === "recording" && !consent &&     <Transition.Root show={!consent} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => cancelRecording()}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                        <div>
                        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-300">
                            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-800" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-main-text-darker">
                            Consent required
                            </Dialog.Title>
                            <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                Recording requires acknowledgement of consent. Please ask everyone in the room for consent to be recorded and provide a verbal confirmation. <span className='font-semibold'>(Disclaimer: Confirmations are recorded.)</span>
                            </p>
                            </div>
                        </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-main-text shadow-sm hover:bg-main-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                            onClick={() => setConsent(true)}
                        >
                            Consent
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => cancelRecording()}
                        >
                            Cancel
                        </button>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
            </Transition.Root>}
    </>)
}