import './App.css';
import { Route, Routes } from "react-router-dom";
import Dashboard from './Dashboard';
import Register from './auth/Register'
import Login from './auth/Login'
import PasswordReset from './auth/PasswordReset'
import UpdatePassword from './auth/UpdatePassword'
import { Analytics } from '@vercel/analytics/react';
import config from './config.json'
import AuthRoute from './auth/AuthRoute';

function App() {
  return (
    <div className=''>
      <Analytics /> 
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path="/" element={<Dashboard key="scribe" capability={'Scribe'}/>} />
          <Route path="/scribe" element={<Dashboard key="scribe" capability={'Scribe'}/>} />
          <Route path="/history" element={<Dashboard key="history" capability={'History'}/>} />
          <Route path="/copilot" element={<Dashboard key="copilot" capability={'Copilot'}/>} />
          <Route path="/templates" element={<Dashboard key="templates" capability={'Templates'}/>} />
          <Route path="/settings" element={<Dashboard key="settings" capability={'Settings'}/>} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/passwordreset" element={<PasswordReset />} />
        <Route path="/update-password" element={<UpdatePassword />} /> */}
      </Routes>
    </div>
  );
}

export default App;
