import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import NeumLogo from './NeumLogo.svg'
import GoogleLogo from './Google.svg'
import {supabase} from "../supabase/client"
import config from '../config.json'

const Register = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState<string|undefined>(undefined);
  const [msg, setMsg] = useState<string|undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { login, loginGoogle, user } = useAuth();
  const logo = config.LOGO
  const branded = config.BRANDED

  const register = (email:string, password:string) =>
    supabase.auth.signUp({ email, password });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setMsg(undefined)
      setErrorMsg(undefined);
      setLoading(true);
      if (!passwordRef.current?.value || !emailRef.current?.value) {
        setErrorMsg("Please fill in the fields");
        return;
      }
      const { data, error } = await register(emailRef.current.value, passwordRef.current.value);
      if (!error && data) {
        setMsg(
          "Registration Successful. Check your email to confirm."
        );
      }
      else if(error){
        let prettyError = ""
        if (error.toString().indexOf("AuthApiError:") != -1){
          prettyError = error.toString().substring(error.toString().indexOf("AuthApiError:")+13)
        }
        setErrorMsg(`Error Creating Account - ${prettyError}`);
      }
    } catch (error) {
      console.log(error)
      setErrorMsg("Error in Creating Account");
    }
    setLoading(false);
  };


  return (
    <>
    <div className="flex h-screen flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-10 w-auto"
          src={branded ? logo : NeumLogo}
          alt="Your Company"
        />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-main-text-darker">
          Sign up for Neum
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-6" onSubmit={handleSubmit} method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-main-text-darker">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  ref={emailRef}
                  className="block w-full rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-main-text-darker">
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  ref={passwordRef}
                  className="block w-full rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-main px-3 py-1.5 text-sm font-semibold leading-6 text-main-text shadow-sm hover:bg-main-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Sign up
              </button>
              {errorMsg && <div className="text-red-600 text-sm mt-4">{errorMsg}</div>}
              {msg && (
                <div className="text-green-600 text-sm mt-4">
                  {msg}
                </div>
              )}
              <p className="pt-4">Already a user? <a className="underline text-blue-600" href="/login">Login</a></p>
            </div>
          </form>

          <div>
            <div className="relative mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-main-text-darker">Or continue with</span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-4">
              <div
                onClick={loginGoogle}
                className="flex w-full items-center justify-center gap-3 rounded-md border border-gray-400 hover:bg-gray-300 px-3 py-1.5 text-main-text-darker focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0] cursor-pointer"
              >
                <img src={GoogleLogo}/>
                <span className="text-sm font-semibold leading-6">Google</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Register;