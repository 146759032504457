import { createContext, useContext, useEffect, useState } from "react";
import {supabase} from '../supabase/client'
import { AuthError, AuthResponsePassword, OAuthResponse, Session, User, UserResponse } from "@supabase/supabase-js";

interface AuthContextType {
  auth: boolean;
  user: User | null | undefined; // Replace 'any' with the actual type of your user, if known
  session: Session | null | undefined; // Replace 'any' with the actual type of your session, if known
  login: (email: string, password: string) => Promise<AuthResponsePassword>;
  loginGoogle: () => Promise<OAuthResponse>;
  signOut: () => Promise<{ error: AuthError | null; }>
  passwordReset: (email: string) => Promise<{ data: {}; error: null; } | { data: null; error: AuthError; }>;
  updatePassword: (updatedPassword: string) => Promise<UserResponse>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const loginGoogle = async () => await supabase.auth.signInWithOAuth({provider: 'google'})

const login = (email:string, password:string) =>
  supabase.auth.signInWithPassword({ email, password });

const signOut = () => supabase.auth.signOut();

const passwordReset = (email:string) =>
  supabase.auth.resetPasswordForEmail(email, {
    redirectTo: "http://scribe.neum.ai/update-password"
  });

const updatePassword = (updatedPassword:string) =>
  supabase.auth.updateUser({ password: updatedPassword });

function AuthProvider({children}:any) {
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState<User | undefined | null>(null);
  const [session, setSession] = useState<Session | undefined | null>(null)
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();
      const { user: currentUser } = data;
      setUser(currentUser ?? undefined);
      setAuth(currentUser ? true : false);
      setLoading(false);
    };
    getUser();
    const getSession =  async () => {
      const {data} = await supabase.auth.getSession();
      const {session} = data
      setSession(session)
    }
    getSession();
    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        setAuth(false);
      } else if (event === "SIGNED_IN") {
        setSession(session)
        setUser(session?.user);
        setAuth(true);
      } else if (event === "SIGNED_OUT") {
        setAuth(false);
        setUser(null);
      }
    });
    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        user,
        session,
        login,
        loginGoogle,
        signOut,
        passwordReset,
        updatePassword,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;