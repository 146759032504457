import { Spinner } from "./Spinner"

interface LoadingProps {
    text:string
}

export const Loading = (props:LoadingProps) => {
    return(
        <div className='flex flex-col justify-center items-center pt-12 gap-y-6'>
            {props.text}
            <Spinner/>
        </div>
    )
}