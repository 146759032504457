import copilot from './profilePics/copilot.png'

// Define the types for your props
interface CopilotProps {
    
}

export default function Copilot(props:CopilotProps) {
    return(<>
    <div className='mx-10 border-b border-gray-400 mb-8 py-4 sm:py-10'>
        <h2 className="text-xl font-semibold">Copilot coming soon...</h2>
        <p className='w-full mb-2'>Built to automate common tasks like sending patient communications, helping patients schedule appointments and fill out intakes.</p>
    </div>
    <div className="flex flex-col justify-center items-center lg:px-40">
        <img src={copilot} className='w-96 h-96 rounded-full'/>
    </div></>)
}