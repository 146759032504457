import React from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    ClockIcon,
    ChatBubbleLeftRightIcon,
    PencilSquareIcon,
    NewspaperIcon,
    Cog6ToothIcon, 
    XMarkIcon,
    Bars3Icon
} from '@heroicons/react/24/outline'
import config from './config.json'
import { profilePics } from './profilePics/ProfilePics';
import Scribe from './Scribe';
import Copilot from './Copilot';
import Settings from './Settings';
import History from './History';
import Templates from './Templates';
import { useAuth } from './auth/AuthProvider';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/solid';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// Define the types for your props
interface DashboardProps {
    capability:string
}

export default function Dashboard(props:DashboardProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  //const main_color = config.MAIN_COLOR
  const logo = config.LOGO
  const capability = props.capability
  const {session, signOut} = useAuth()

  const navigation = [
    { name: 'Scribe', href: '/scribe', icon: PencilSquareIcon, current: capability == "Scribe" },
    { name: 'History', href: '/history', icon: ClockIcon, current:  capability == "History" }
    // { name: 'Copilot', href: '/copilot', icon: ChatBubbleLeftRightIcon, current:  capability == "Copilot" },
  ]
  
  const configurations = [
    { name: 'Templates', href: '/templates', icon: NewspaperIcon, current: capability == "Templates" },
    // { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, current: capability == "Settings" },
  ]

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-main-text" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className={`flex grow flex-col gap-y-5 overflow-y-auto bg-main px-6 pt-2 pb-2`}>
                    <div className="text-2xl text-main-text font-bold h-10 flex h-16 shrink-0 items-center">
                      {config.BRANDED && <img
                        className="h-12 w-auto"
                        src={logo}
                        alt="Company logo"
                      />}
                      {config.BRANDED ? '':'Neum'} 
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? `bg-main-darker text-main-text`
                                      : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? `text-main-text` : `text-accent-text group-hover:text-main-text`,
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className={`text-xs font-semibold leading-6 text-accent-text`}>Configurations</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {configurations.map((config_item) => (
                              <li key={config_item.name}>
                                <a
                                  href={config_item.href}
                                  className={classNames(
                                    config_item.current
                                      ? `bg-main-darker text-main-text`
                                      : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                <config_item.icon
                                    className={classNames(
                                      config_item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                />
                                <span className="truncate">{config_item.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto w-full">
                          <div className="relative w-full">
                            <div className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 w-full">
                              <button onClick={signOut} className='flex justify-end bg-accent-button text-accent-button-text font-normal border-accent-button border rounded-md text-md p-1 hover:bg-accent-button-hover w-full items-center justify-center h-12 font-semibold'>
                                Sign out
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className={`flex grow flex-col gap-y-5 overflow-y-auto bg-main px-6`}>
            <div className="flex h-16 shrink-0 items-center pt-8">
              {config.BRANDED && <img
                  className="h-14 w-auto"
                  src={logo}
                  alt="Company logo"
                />}
                {!config.BRANDED && <div className='text-2xl text-main-text font-bold h-10 w-auto'>
                  Neum
                </div>}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? `bg-main-darker text-main-text`
                              : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className={`text-xs font-semibold leading-6 text-accent-text`}>Configurations</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {configurations.map((config_item) => (
                      <li key={config_item.name}>
                        <a
                          href={config_item.href}
                          className={classNames(
                            config_item.current
                              ? `bg-main-darker text-main-text`
                              : `text-accent-text hover:text-main-text hover:bg-main-darker`,
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                        <config_item.icon
                            className={classNames(
                              config_item.current ? 'text-main-text' : `text-accent-text group-hover:text-main-text`,
                                'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                        />
                        <span className="truncate">{config_item.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto w-full">
                  <div className="relative w-full">
                    <div className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-main-text w-full">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className={`h-8 w-8 rounded-full bg-main-darker`}
                        src={profilePics.robot}
                        alt=""
                      />
                      <span className="hidden lg:flex lg:items-center text-main-text font-semibold">
                        <span className="sr-only">Your profile</span>
                        <span aria-hidden="true">{session?.user.user_metadata['full_name']}</span>
                      </span>
                      <button onClick={signOut} className='flex justify-end text-accent-text font-normal bg-accent-button rounded-md text-sm p-1 hover:bg-accent-button-hover font-semibold'>
                        Sign out
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={`sticky top-0 z-40 flex items-center gap-x-6 bg-main px-4 py-4 shadow-sm sm:px-6 lg:hidden`}>
          <button type="button" className={`-m-2.5 p-2.5 text-accent-text lg:hidden`} onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-main-text">{config.BRANDED ? config.NAME : "Neum"}</div>
          <a href="#">
            <span className="sr-only">Your profile</span>
            <img
              className={`h-8 w-8 rounded-full bg-main-darker`}
              src={profilePics.robot}
              alt=""
            />
          </a>
        </div>

        <main className="lg:pl-72 lg:h-screen">
          <div className="px-4 sm:px-6 lg:px-8 h-full">{
            <>
            {capability == "Scribe" && <Scribe />}
            {capability == "Copilot" && <Copilot />}
            {capability == "History" && <History />}
            {capability == "Templates" && <Templates/>}
            {capability == "Settings" && <Settings />}
            </>
          }</div>
        </main>
      </div>
    </>
  )
}
