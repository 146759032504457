import { Fragment, useState, useEffect } from 'react'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { LinkIcon, PlusIcon, EllipsisVerticalIcon, QuestionMarkCircleIcon, SparklesIcon } from '@heroicons/react/20/solid'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { createTemplate, fetchTemplates } from './ServerActions';
import { useAuth } from "./auth/AuthProvider"
import { downloadSupabaseFileAsString, updateToSupabase, uploadToSupabase } from './supabase/client';
import { v4 as uuidv4 } from 'uuid';
import { CheckIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { TemplateObject } from './utils/TemplateObject';
import { Spinner } from './utils/Spinner';

// Define the types for your props
interface TemplatesProps {

}

const presetTemplates = [
    { id:"summary_template", organization: "Neum", name: 'Summary Template', initials: 'ST', bgColor: 'bg-sky-600'},
    { id:"support_template", organization: "Neum", name: 'Support Template', initials: 'ST', bgColor: 'bg-pink-600'},
    { id:"sales_template", organization: "Neum", name: 'Sales Template', initials: 'ST', bgColor: 'bg-green-600'},
  ]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

export default function Templates(props:TemplatesProps) {
    const [openTemplate , setOpenTemplate] = useState<boolean>(false)
    const [isPreBuilt, setIsPreBuilt] = useState<boolean>(false)
    const [selectTemplateId, setSelectTemplateId] = useState<string>()
    const [displayTemplateBuilder, setDisplayTemplateBuilder] = useState<string>()
    const [templateBuilder, setTemplateBuilder] =  useState<string|undefined>()
    const [templateBuilderName, setTemplateBuilderName] = useState<string>()
    const [loadingTemplates , setLoadingTemplates] = useState<string|undefined>(undefined)
    const [loadingTemplatesBuilder , setLoadingTemplatesBuilder] = useState<string|undefined>(undefined)
    const [templates, setTemplates] = useState<TemplateObject[]>()
    const {session} = useAuth()
    const [newTemplate, setNewTemplate] = useState<boolean>(false)
    const [updateTemplate, setUpdateTemplate] = useState<boolean>(false)
    const [error, setError] = useState<string|undefined>(undefined)
    const [errorTimer, setErrorTimer] = useState<number>(0);

    const getTemplates = async () => {
        if(session){
            setLoadingTemplates("Loading templates 📃...")
            let temp_templates : TemplateObject[] = await fetchTemplates(session.access_token)
            console.log(temp_templates)
            setTemplates(temp_templates)
            setLoadingTemplates(undefined)
        }
    }

    useEffect(() => {
        getTemplates()
    }, [])

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (error) {
            setErrorTimer(0); // Reset progress
            timer = setTimeout(() => {
                setError(undefined);
            }, 3000); // 5000ms = 5 seconds
    
            // Update progress every 50ms
            interval = setInterval(() => {
                setErrorTimer((oldProgress) => {
                    console.log(oldProgress)
                    if (oldProgress < 100) {
                        return oldProgress + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 30);
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [error]);

    function createTemplateBuilder(): void {
        setNewTemplate(true)
        setUpdateTemplate(false)
        setOpenTemplate(true)
    }

    async function editTemplate(templateId:string, name:string, prebuilt:boolean = false): Promise<void> {
        setSelectTemplateId(templateId)
        setIsPreBuilt(prebuilt)
        setNewTemplate(false)
        setUpdateTemplate(true)
        setOpenTemplate(true)
        setLoadingTemplatesBuilder("Fetching template 📃...")
        let temp_template = await downloadSupabaseFileAsString("templates", templateId + ".txt?" + uuidv4())
        setTemplateBuilderName(name)
        setTemplateBuilder(temp_template)
        setDisplayTemplateBuilder(SpacingText(temp_template))
        setLoadingTemplatesBuilder(undefined)
    }

    const SpacingText = (text:string) => {
        // New lines to HTML
        const textWithLineBreaks = text.replace(/\n/g, '<br>');
        return textWithLineBreaks
    }

    async function store_or_update_template(): Promise<void> {
        if(!templateBuilderName || !templateBuilder){
            //Add error handling
            console.log("error")
            setError("Fill out both template name and body.")
            return
        }
        else if((newTemplate || isPreBuilt) && session){
            setLoadingTemplatesBuilder("Saving your template 📄...")
            let templateId = uuidv4()
            const blob = new Blob([templateBuilder], {type: 'text/plain'})
            const file = new File([blob], `${templateId}.txt`, {type: 'text/plain'})
            await uploadToSupabase(file,`${session?.user.email}/${templateId}.txt`, 'templates')
            await createTemplate(templateId,templateBuilderName, session?.access_token)
            await getTemplates()
            closeTemplateBuilder()
            setLoadingTemplatesBuilder(undefined)
        }
        else if(updateTemplate && !isPreBuilt){
            setLoadingTemplatesBuilder("Updating your template 📄...")
            const blob = new Blob([templateBuilder], {type: 'text/plain'})
            const file = new File([blob], `${selectTemplateId}.txt`, {type: 'text/plain'})
            await updateToSupabase(file,`${selectTemplateId}.txt`, 'templates')
            await getTemplates()
            closeTemplateBuilder()
            setLoadingTemplatesBuilder(undefined)
        }
    }

    const closeTemplateBuilder = () => {
        setOpenTemplate(false)
        setTemplateBuilder(undefined)
        setTemplateBuilderName(undefined)
        setDisplayTemplateBuilder(undefined)
        setIsPreBuilt(false)
    }

    return(<>
        {openTemplate && <Transition.Root show={openTemplate} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeTemplateBuilder}>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16 pt-12 sm:pt-0">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                            <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                <div className="h-0 flex-1 overflow-y-auto">
                                <div className="bg-main-darker px-4 py-6 sm:px-6">
                                    <div className="flex items-center justify-between">
                                    <Dialog.Title className="text-base font-semibold leading-6 text-main-text">
                                        Template Builder
                                    </Dialog.Title>
                                    <div className="ml-3 flex h-7 items-center">
                                        <button
                                        type="button"
                                        className="relative rounded-md bg-main-darker text-accent-text hover:text-main-text focus:outline-none focus:ring-2 focus:ring-white"
                                        onClick={() => closeTemplateBuilder()}
                                        >
                                        <span className="absolute -inset-2.5" />
                                        <span className="sr-only">Close panel</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    </div>
                                    <div className="mt-1">
                                    <p className="text-sm text-accent-text">
                                        Construct your desired template below.
                                    </p>
                                    </div>
                                </div>
                                <div className="flex flex-1 flex-col justify-between">
                                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                        {loadingTemplatesBuilder && <div className='flex flex-col justify-center items-center pt-12 gap-y-6'>
                                            {loadingTemplatesBuilder}
                                            <Spinner/>
                                        </div>}
                                        {!loadingTemplatesBuilder && <div className="space-y-6 pb-5 pt-6">
                                            <div>
                                                <label
                                                    htmlFor="project-name"
                                                    className="block text-sm font-medium leading-6 text-main-text-darker"
                                                >
                                                    Template Name
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                    type="text"
                                                    placeholder='Vaccine Template'
                                                    value={templateBuilderName}
                                                    className="block w-full rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                    onChange={(event) => setTemplateBuilderName(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                                <div>
                                                    <div className='flex justify-between'>
                                                        <label
                                                            htmlFor="description"
                                                            className="block text-sm font-medium leading-6 text-main-text-darker self-center"
                                                        >
                                                            Template Format
                                                        </label>
                                                        {/* <button
                                                            className="ml-4 inline-flex justify-between items-center rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-main-text-darker shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 w-36"
                                                        >
                                                            Neum AI
                                                            <SparklesIcon className='w-6'/>
                                                        </button> */}
                                                    </div>
                                                    <div className="mt-2">
                                                        <ReactQuill
                                                            theme='snow'
                                                            placeholder='Heartbeat: [default to normal if not mentioned]'
                                                            className='block w-full h-[500px] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                                            value={displayTemplateBuilder}
                                                            modules={{toolbar: false}}
                                                            onChange={(value, d, s, editor) => {
                                                                setDisplayTemplateBuilder(value)
                                                                setTemplateBuilder(editor.getText())
                                                            }}
                                                        />
                                                    </div>
                                                    <Disclosure as="div" className="mt-4">
                                                    {({ open }) => (
                                                        <>
                                                            <Disclosure.Button className={`flex w-full justify-between ${open ? "rounded-t-lg" : "rounded-lg"} bg-accent px-4 py-2 text-left text-lg font-medium text-gray-900 hover:bg-selected focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}>
                                                                Cheat Sheet
                                                            </Disclosure.Button>
                                                            <Disclosure.Panel className="px-6 pb-6 pt-6 text-sm text-gray-500 border border-gray-300 rounded-b-lg">
                                                                <div className='p-4'>
                                                                    General guidance to create templates:
                                                                    <ul className='w-full flex flex-col gap-y-4'>
                                                                        <li className='flex flex-row gap-x-4 items-center justify-start w-full'>
                                                                            <CheckIcon className='h-4 w-4 flex-shrink-0'/>
                                                                            <p>Add entries for the different pieces of data you want to capture. Ex. Diagnosis</p>
                                                                        </li>
                                                                        <li className='flex flex-row gap-x-4 items-center justify-start w-full'>
                                                                            <CheckIcon className='h-4 w-4 flex-shrink-0'/>
                                                                            <p>For each data piece add a description in between paranthesis. Ex. Diagnosis : [Information related to what is wrong with the animal.]</p>
                                                                        </li>
                                                                        <li className='flex flex-row gap-x-4 items-center justify-start w-full'>
                                                                            <CheckIcon className='h-4 w-4 flex-shrink-0'/>
                                                                            <p>Add default values for entries. Ex. Heart Rate: [Hearbeat rate for animal. Default to normal]</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                    </Disclosure>
                                                </div>
                                        </div>}
                                    </div>
                                </div>
                                </div>
                                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                <button className="rounded-md bg-accent-button px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-accent-button-hover text-accent-button-text" onClick={() => setOpenTemplate(false)}>
                                    Cancel
                                </button>
                                <button className="ml-4 inline-flex justify-center rounded-md bg-main-button px-3 py-2 text-sm font-semibold text-main-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                onClick={() => store_or_update_template()}>
                                    Save
                                </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </div>
            </Dialog>
        </Transition.Root>}
        <div className="border-b border-gray-400 pb-5 mb-5 flex justify-between gap-y-10 py-4 sm:py-10">
            <div>
                <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                    Templates
                </h2>
                <p className="mt-2 max-w-4xl text-sm text-gray-500">
                    Create new templates or modify presets to let Neum AI know how to organize and present your notes.
                </p>
            </div>
            <div className="mt-4 flex md:ml-4 md:mt-0">
                <button
                type="button"
                className="ml-3 inline-flex gap-x-2 items-center rounded-md bg-main-button px-3 py-2 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 h-12 self-center"
                onClick={() => createTemplateBuilder()}
                >
                    <PlusIcon className="-mr-0.5 sm:h-5 sm:w-5 h-10 w-10" aria-hidden="true" />
                    <div className='hidden sm:block'>Create template</div>
                </button>
            </div>
        </div>
        {loadingTemplates && <div className='flex flex-col justify-center items-center pt-12 gap-y-6'>
            {loadingTemplates}
            <Spinner/>
        </div>}
        {!loadingTemplates && <div className='flex flex-col gap-y-10'>
            {templates && templates.length > 0 && <div>
                <h2 className="text-sm font-medium text-gray-500">Your templates</h2>
                <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
                    {templates.map((template) => (
                    <li key={template.name} className="col-span-1 flex rounded-md shadow-sm h-16">
                        <div className={'bg-main flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text'}>
                        {template.name[0]}
                        </div>
                        <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-400 bg-white hover:bg-gray-200 cursor-pointer" onClick={() => editTemplate(template.organization + "/" + template.id, template.name, false)}>
                            <div className="flex-1 truncate px-4 py-2 text-sm">
                                {template.name}
                            </div>
                            <div className="flex-shrink-0 pr-2">
                            </div>
                        </div>
                    </li>
                    ))}
                </ul>
            </div>}
            <div>
                <h2 className="text-sm font-medium text-gray-500">Built-in templates</h2>
                <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3">
                    {presetTemplates.map((template) => (
                    <li key={template.name} className="col-span-1 flex rounded-md shadow-sm h-16">
                        <div
                        className={classNames(
                            template.bgColor,
                            'flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-main-text'
                        )}
                        >
                        {template.initials}
                        </div>
                        <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-400 bg-white hover:bg-gray-200 cursor-pointer" onClick={() => editTemplate(template.organization + "/" + template.id, template.name, true)}>
                            <div className="flex-1 truncate px-4 py-2 text-sm">
                                {template.name}
                            </div>
                            <div className="flex-shrink-0 pr-2">
                                {/* <button
                                type="button"
                                className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                >
                                <span className="sr-only">Open options</span>
                                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                </button> */}
                            </div>
                        </div>
                    </li>
                    ))}
                </ul>
            </div>
        </div>}
        {error && <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                show={error != undefined}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0">
                                <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-main-text-darker">{error}</p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                <button
                                    type="button"
                                    className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => {
                                        setError(undefined)
                                    }}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                                </div>
                            </div>
                        </div>
                        <div className='w-full bg-gray-500'>
                                <div className={`bg-red-500 h-[5px]`} style={{width:`${errorTimer}%`}}/>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>}
    </>)
}