import { createClient } from "@supabase/supabase-js";
const tus = require('tus-js-client')

const projectURL = "https://auth.neum.ai";
const projectKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9paW1iYnl3ZmhtZ2Jjc2hlc3FuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDU1Mjg2NzAsImV4cCI6MjAyMTEwNDY3MH0.XAB9xWxxu0UVFvsZsLVxy5airdO3yk_crgg0mD-8iQo";

export const supabase = createClient(projectURL, projectKey);

export const createSignedURL = async (bucket, fileName) =>
{
    try {
        const { data, error } = await supabase.storage.from(bucket).createSignedUrl(fileName, 1800);
        if (error) {
          console.error('Error updating file:', error.message);
          return ''
        } 
        return data.signedUrl
    } catch (error) {
      console.error('Error updating files:', error.message);
      return ''
    }
}

export const updateToSupabase = async (file, fileName, bucket) => {
    try {
        const { dat, error } = await supabase.storage.from(bucket).update(fileName, file);
        if (error) {
          console.error('Error updating file:', error.message);
        } 
    } catch (error) {
      console.error('Error updating files:', error.message);
    }
};

export const uploadRecordingToSupabase = async (file, fileName, bucket) => {

    return new Promise((resolve, reject) => {
        var upload = new tus.Upload(file, {
            endpoint: `https://oiimbbywfhmgbcshesqn.supabase.co/storage/v1/upload/resumable`,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            headers: {
                authorization: `Bearer ${projectKey}`,
                'x-upsert': 'true', // optionally set upsert to true to overwrite existing files
            },
            uploadDataDuringCreation: true,
            removeFingerprintOnSuccess: true, // Important if you want to allow re-uploading the same file https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsuccess
            metadata: {
                bucketName: bucket,
                objectName: fileName,
                contentType: 'audio/mpeg',
                cacheControl: 3600,
            },
            chunkSize: 6 * 1024 * 1024, // NOTE: it must be set to 6MB (for now) do not change it
            onError: function (error) {
                console.log('Failed because: ' + error)
                reject(error)
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
                console.log(bytesUploaded, bytesTotal, percentage + '%')
            },
            onSuccess: function () {
                console.log('Download %s from %s', upload.file.name, upload.url)
                resolve()
            },
        })


        // Check if there are any previous uploads to continue.
        return upload.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one.
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0])
            }

            // Start the upload
            upload.start()
        })
    })
}

export const uploadToSupabase = async (file, fileName, bucket) => {
    try {
        const { dat, error } = await supabase.storage.from(bucket).upload(fileName, file);
        if (error) {
          console.error('Error uploading file:', error.message);
        } 
    } catch (error) {
      console.error('Error uploading files:', error.message);
    }
};

export async function downloadSupabaseFileAsString(bucket, fileName) {
    const maxAttempts = 5;
    let attempts = 0;

    while (attempts < maxAttempts) {
        try {
            // Create a path for the file
            const filePath = `${fileName}`;
            // Get the file from Supabase
            const { data, error } = await supabase.storage.from(bucket).download(filePath);

            if (error) {
                throw error;
            }

            // Use FileReader to read the blob as text
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
                reader.readAsText(data);
            });
        } catch (error) {
            attempts++;
            console.error('Attempt', attempts, 'failed to download file:', error.message);
            if (attempts < maxAttempts) {
                // Wait for 1 second before the next attempt
                await new Promise(resolve => setTimeout(resolve, 1000));
            } else {
                // Re-throw the error after all attempts fail
                throw error;
            }
        }
    }
}
