import { CheckCircleIcon, CheckIcon, ChevronUpDownIcon, ClipboardDocumentIcon, CloudArrowUpIcon, ShareIcon, SparklesIcon } from "@heroicons/react/24/solid"
import { profilePics } from "./profilePics/ProfilePics"
import { Disclosure, Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronRightIcon, PlusCircleIcon, DocumentTextIcon, ArrowLongLeftIcon } from '@heroicons/react/20/solid'
import { Fragment, useEffect, useState } from "react"
import { useAuth } from "./auth/AuthProvider"
import { fetchSessions, fetchTemplates, generateSoapNotes } from "./ServerActions"
import { useNavigate } from "react-router-dom"
import { createSignedURL, downloadSupabaseFileAsString, updateToSupabase } from "./supabase/client"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import config from './config.json'
import { TemplateObject } from "./utils/TemplateObject"
import { Spinner } from "./utils/Spinner"
import { Loading } from "./utils/Loading"

// Define the types for your props
interface HistoryProps {
    
}

interface SessionStatus {
    transcript:string,
    notes:string,
    comms:string,
    manual_notes:string
}

interface SessionObject {
    id:string,
    created_timestamp:number,
    modified_timestamp:number,
    name:string,
    organization:string|undefined,
    owner:string,
    status:SessionStatus
}

function convertTimestampToDate(created_timestamp:number) {
    // Convert timestamp from seconds to milliseconds
    const date = new Date(created_timestamp * 1000);

    // Format the date to a human-readable format
    const humanReadableDate = date.toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    return humanReadableDate;
}

const HighlightText = (text:string) => {
    const textWithLineBreaks = SpacingText(text)
    // Hihglight bracketed areas
    const regex = /\[(.*?)\]/g;
    const  highlightedText = textWithLineBreaks.replace(regex, '[<span style="background-color: yellow;">$1</span>]');
    return highlightedText
}

const SpacingText = (text:string) => {
    // New lines to HTML
    const textWithLineBreaks = text.replace(/\n/g, '<br>');
    return textWithLineBreaks
}

function displaySessionsInOrder(sessions: SessionObject[]) {
    const sortedSessions = sessions.sort((a, b) => b.created_timestamp- a.created_timestamp);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today.getTime() - 86400000); // 24 hours in milliseconds
    const lastWeekStart = new Date();
    lastWeekStart.setDate(today.getDate() - (today.getDay() + 6) % 7 - 7);
    const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    let buckets: { [key: string]: SessionObject[] } = {
        today: [],
        yesterday: [],
        thisWeek: [],
        lastWeek: [],
        thisMonth: [],
        catchAll: []
    };

    sortedSessions.forEach(session => {
        const sessionDate = new Date(session.created_timestamp * 1000); // Convert Unix timestamp to JavaScript Date object
        if (sessionDate >= today) {
            buckets.today.push(session);
        } else if (sessionDate >= yesterday && sessionDate < today) {
            buckets.yesterday.push(session);
        } else if (sessionDate >= lastWeekStart && sessionDate < today) {
            buckets.lastWeek.push(session);
        } else if (sessionDate >= thisMonthStart && sessionDate < lastWeekStart) {
            buckets.thisWeek.push(session);
        } else if (sessionDate >= thisMonthStart) {
            buckets.thisMonth.push(session);
        } else {
            buckets.catchAll.push(session);
        }
    });

    return buckets;
}

export default function History(props:HistoryProps) {
    const [sessions, setSessions] = useState<{[key: string]: SessionObject[]}>()
    const [availableSession, setAvailableSessions] = useState<boolean>(false)
    const [activeSession, setActiveSession] = useState<SessionObject|undefined>(undefined)
    const [activeTranscript, setActiveTranscript] = useState<string|undefined>(undefined)
    const [activeNotes, setActiveNotes] = useState<string|undefined>(undefined)
    const [displayActiveNotes, setDisplayActiveNotes] = useState('');
    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const [copyTimer, setCopyTimer] = useState<number>(0);
    const [notesLoading, setNotesLoading] = useState<string|undefined>(undefined)
    const [activeManualNotes, setActiveManualNotes] = useState<string|undefined>(undefined)
    const [activeRecording, setActiveRecording] = useState<string|undefined>(undefined); 
    const [loadingSession , setLoadingSessions] = useState<boolean>(false)
    const [loading, setLoading] = useState<string|undefined>(undefined)
    const {session} = useAuth()
    const  naviagate = useNavigate()
    
    // Template Variables
    const preBuiltTemplate : Array<TemplateObject> = [{
        id: "summary_template",
        name: "Summary Notes",
        owner: "Neum",
        organization:"Neum",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now()
    },
    {
        id: "support_template",
        name: "Support Notes",
        owner: "Neum",
        organization:"Neum",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now()
    },
    {
        id: "sales_template",
        name: "Sales Notes",
        owner: "Neum",
        organization:"Neum",
        created_timestamp: Date.now(),
        modified_timestamp: Date.now()
    }]
    const [loadingTemplates , setLoadingTemplates] = useState<boolean>(false)
    const [templates, setTemplates] = useState<TemplateObject[]>()
    const [template, setTemplate] = useState<TemplateObject>(preBuiltTemplate[0])

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (copySuccess) {
            setCopyTimer(0); // Reset progress
            timer = setTimeout(() => {
                setCopySuccess(false);
            }, 1000); // 5000ms = 5 seconds
    
            // Update progress every 50ms
            interval = setInterval(() => {
                setCopyTimer((oldProgress) => {
                    console.log(oldProgress)
                    if (oldProgress < 100) {
                        return oldProgress + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 30);
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [copySuccess]);

    useEffect(() => {
        const getSessions = async () => {
            if(session){
                setLoadingSessions(true)
                let temp_sessions : SessionObject[] = await fetchSessions(session.access_token)
                setAvailableSessions(temp_sessions.length != 0)
                setSessions(displaySessionsInOrder(temp_sessions))
                setLoadingSessions(false)
            }
        }
        const getTemplates = async () => {
            if(session){
                setLoadingTemplates(true)
                let temp_templates : TemplateObject[] = await fetchTemplates(session.access_token)
                setTemplates([...preBuiltTemplate, ...temp_templates])
                setLoadingTemplates(false)
            }
        }
        getTemplates()
        getSessions()
    }, [])

    async function selectSession(sessionObject:SessionObject): Promise<void> {
        setActiveSession(sessionObject)
        setActiveNotes(undefined)
        setActiveTranscript(undefined)
        setActiveManualNotes(undefined)
        setActiveRecording(undefined)
        if(sessionObject.status.transcript == "Completed"){
            setLoading("Fetching Recording 💿...")
            setActiveRecording(await createSignedURL("recordings", sessionObject.organization + "/" + sessionObject.id + ".mp3"))
            setLoading("Fetching Transcript 📄...")
            setActiveTranscript(await downloadSupabaseFileAsString("transcripts", sessionObject.organization + "/" + sessionObject.id + "_transcript.txt"))
        }
        if(sessionObject.status.notes == "Completed"){
            setLoading("Fetching Notes 🤖...")
            let temp_notes = await downloadSupabaseFileAsString("notes", sessionObject.organization + "/" + sessionObject.id +"_notes.txt")
            setActiveNotes(temp_notes)
            setDisplayActiveNotes(HighlightText(temp_notes))
        }
        if(sessionObject.status.manual_notes == "Completed"){
            setLoading("Fetching your Notes 📒...")
            setActiveManualNotes(await downloadSupabaseFileAsString("manual_notes", sessionObject.organization + "/" + sessionObject.id + "_manualnotes.txt"))
        }
        setLoading(undefined)
    }

    async function processTranscript(): Promise<void> {
        setLoading("Generating notes 🤖")
        if (session && activeSession)
        {
            await generateSoapNotes(activeSession?.id, activeSession?.name, template.organization + "/" + template.id, activeTranscript != undefined , activeNotes != undefined, session.access_token)
            //Download notes from supabase
            setLoading("Fetching notes 📄...")
            setActiveNotes(await downloadSupabaseFileAsString("notes", activeSession.organization+ "/" + activeSession?.id +"_notes.txt"))
            setLoading(undefined)
        }
    }

    const copyToClipboard = async (text: string): Promise<void> => {
        try {
          await navigator.clipboard.writeText(text);
          setCopySuccess(true);
        } catch (err) {
          setCopySuccess(false);
        }
    };

    async function saveNotes(): Promise<void> {
        if(activeNotes)
        {
            setNotesLoading("Saving your notes 💾...")
            const blob = new Blob([activeNotes], {type: 'text/plain'})
            const file = new File([blob], `${activeSession?.id}_notes.txt`, {type: 'text/plain'})
            await updateToSupabase(file,`${activeSession?.id}_notes.txt`, 'notes')
            setNotesLoading(undefined)
        }
    }

    return(
        <div className="overflow-hidden flex py-4 sm:py-10">
            <div className={`${!activeSession ? 'w-screen' : 'w-0 p-0'} sm:w-96`}>
                <div className={`p-4 text-xl border-b border-gray-400 font-semibold ${!activeSession ? 'block sm:hidden' : 'hidden'}`}>
                    <h2>Session History</h2>
                </div>
                <ul role="list" className="divide-y divide-gray-100 w-full thin-scrollbar max-h-[91vh] overflow-y-auto sm:pr-4 pt-2">
                    {loadingSession && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-4'>
                        Loading sessions 🥼...
                        <Spinner />
                    </div>}
                    {sessions && sessions['today'].length > 0 && <div>
                        <li className="py-1 pl-4 text-main-text-darker/50 font-semibold text-xs">Today</li>
                        {sessions['today'].map((session) => (
                        <li className={`flex items-center justify-between gap-x-6 py-5 ${activeSession && session.id == activeSession.id ? 'bg-selected' : 'hover:bg-gray-300'} cursor-pointer rounded-md`} onClick={() => selectSession(session)}>
                            <div className="flex min-w-0 gap-x-4 pl-4 justify-center items-center">
                                <div className="sm:h-12 sm:w-12 h-7 w-7 flex rounded-full bg-main items-center justify-center">
                                    <p className="text-center text-main-text">{session.name == "" ? "P" : session.name[0].toUpperCase()}</p>
                                </div>
                                <div className="min-w-0 flex-auto self-center">
                                    <p className={`text-sm ${activeSession && session.id == activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker`}>{session.name == "" ? "Session" : session.name}</p>
                                    <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                                </div>
                            </div>
                            <ChevronRightIcon className="h-5"/>
                        </li>
                        ))}
                    </div>}
                    {sessions && sessions['yesterday'].length > 0 && <div>
                        <li className="py-1 pl-4 text-main-text-darker/50 font-semibold text-xs pt-6">Yesterday</li>
                        {sessions['yesterday'].map((session) => (
                        <li className={`flex items-center justify-between gap-x-6 py-5 ${activeSession && session.id == activeSession.id ? 'bg-selected' : 'hover:bg-gray-300'} cursor-pointer rounded-md`} onClick={() => selectSession(session)}>
                            <div className="flex min-w-0 gap-x-4 pl-4 justify-center items-center">
                                <div className="sm:h-12 sm:w-12 h-7 w-7 flex rounded-full bg-main items-center justify-center">
                                    <p className="text-center text-main-text">{session.name == "" ? "P" : session.name[0].toUpperCase()}</p>
                                </div>
                                <div className="min-w-0 flex-auto self-center">
                                    <p className={`text-sm ${activeSession && session.id == activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker`}>{session.name == "" ? "Session" : session.name}</p>
                                    <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                                </div>
                            </div>
                            <ChevronRightIcon className="h-5"/>
                        </li>
                        ))}
                    </div>}
                    {sessions && sessions['thisWeek'].length > 0 && <div>
                        <li className="py-1 pl-4 text-main-text-darker/50 font-semibold text-xs pt-6">This week</li>
                        {sessions['thisWeek'].map((session) => (
                        <li className={`flex items-center justify-between gap-x-6 py-5 ${activeSession && session.id == activeSession.id ? 'bg-selected' : 'hover:bg-gray-300'} cursor-pointer rounded-md`} onClick={() => selectSession(session)}>
                            <div className="flex min-w-0 gap-x-4 pl-4 justify-center items-center">
                                <div className="sm:h-12 sm:w-12 h-7 w-7 flex rounded-full bg-main items-center justify-center">
                                    <p className="text-center text-main-text">{session.name == "" ? "P" : session.name[0].toUpperCase()}</p>
                                </div>
                                <div className="min-w-0 flex-auto self-center">
                                    <p className={`text-sm ${activeSession && session.id == activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker`}>{session.name == "" ? "Session" : session.name}</p>
                                    <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                                </div>
                            </div>
                            <ChevronRightIcon className="h-5"/>
                        </li>
                        ))}
                    </div>}
                    {sessions && sessions['lastWeek'].length > 0 && <div>
                        <li className="py-1 pl-4 text-main-text-darker/50 font-semibold text-xs pt-6">Last week</li>
                        {sessions['lastWeek'].map((session) => (
                        <li className={`flex items-center justify-between gap-x-6 py-5 ${activeSession && session.id == activeSession.id ? 'bg-selected' : 'hover:bg-gray-300'} cursor-pointer rounded-md`} onClick={() => selectSession(session)}>
                            <div className="flex min-w-0 gap-x-4 pl-4 justify-center items-center">
                                <div className="sm:h-12 sm:w-12 h-7 w-7 flex rounded-full bg-main items-center justify-center">
                                    <p className="text-center text-main-text">{session.name == "" ? "P" : session.name[0].toUpperCase()}</p>
                                </div>
                                <div className="min-w-0 flex-auto self-center">
                                    <p className={`text-sm ${activeSession && session.id == activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker`}>{session.name == "" ? "Session" : session.name}</p>
                                    <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                                </div>
                            </div>
                            <ChevronRightIcon className="h-5"/>
                        </li>
                        ))}
                    </div>}
                    {sessions && sessions['thisMonth'].length > 0 && <div>
                        <li className="py-1 pl-4 text-main-text-darker/50 font-semibold text-xs pt-6">This month</li>
                        {sessions['thisMonth'].map((session) => (
                        <li className={`flex items-center justify-between gap-x-6 py-5 ${activeSession && session.id == activeSession.id ? 'bg-selected' : 'hover:bg-gray-300'} cursor-pointer rounded-md`} onClick={() => selectSession(session)}>
                            <div className="flex min-w-0 gap-x-4 pl-4 justify-center items-center">
                                <div className="sm:h-12 sm:w-12 h-7 w-7 flex rounded-full bg-main items-center justify-center">
                                    <p className="text-center text-main-text">{session.name == "" ? "P" : session.name[0].toUpperCase()}</p>
                                </div>
                                <div className="min-w-0 flex-auto self-center">
                                    <p className={`text-sm ${activeSession && session.id == activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker`}>{session.name == "" ? "Session" : session.name}</p>
                                    <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                                </div>
                            </div>
                            <ChevronRightIcon className="h-5"/>
                        </li>
                        ))}
                    </div>}
                    {sessions && sessions['catchAll'].length > 0 && <div>
                        <li className="py-1 pl-4 text-main-text-darker/50 font-semibold text-xs pt-6">More...</li>
                        {sessions['catchAll'].map((session) => (
                        <li className={`flex items-center justify-between gap-x-6 py-5 ${activeSession && session.id == activeSession.id ? 'bg-selected' : 'hover:bg-gray-300'} cursor-pointer rounded-md`} onClick={() => selectSession(session)}>
                            <div className="flex min-w-0 gap-x-4 pl-4 justify-center items-center">
                                <div className="sm:h-12 sm:w-12 h-7 w-7 flex rounded-full bg-main items-center justify-center">
                                    <p className="text-center text-main-text">{session.name == "" ? "P" : session.name[0].toUpperCase()}</p>
                                </div>
                                <div className="min-w-0 flex-auto self-center">
                                    <p className={`text-sm ${activeSession && session.id == activeSession.id ? 'font-bold' : 'font-semibold'} leading-6 text-main-text-darker`}>{session.name == "" ? "Session" : session.name}</p>
                                    <p className="text-xs">{convertTimestampToDate(session.created_timestamp)}</p>
                                </div>
                            </div>
                            <ChevronRightIcon className="h-5"/>
                        </li>
                        ))}
                    </div>}
                    {(!sessions || !availableSession) && !loadingSession && <li>
                        <button
                            type="button"
                            className="relative flex flex-col justify-center items-center w-full rounded-lg border-2 border-dashed border-gray-400 p-8 text-center hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => naviagate('/scribe')}
                            >
                                <PlusCircleIcon className="h-12 w-12 text-blue-600"/>
                                <span className="mt-2 block text-sm font-semibold text-main-text-darker">Start a session</span>
                        </button>
                    </li>}
                </ul>
            </div>
            <div className={`${!activeSession ? 'w-0 hidden' : 'w-full px-4'} sm:w-full sm:block sm:pl-12 sm:px-8`}>
                <div className="py-8 hidden sm:block sm:py-0">
                    <button
                    type="button"
                    className={`relative -ml-px inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter sm:hidden`}
                    onClick={() => setActiveSession(undefined)}
                    >
                        <ArrowLongLeftIcon className="-ml-0.5 h-5 w-5 text-main-text" aria-hidden="true" />
                        Change Session
                    </button>
                </div>      
                {loading && <div className='flex flex-col align-center items-center mt-2 w-full h-80 justify-center gap-y-4'>
                    <p>{loading}</p>
                    <Spinner />
                </div>}         
                {!activeSession && !loading && <div className="w-full">
                    <div className="md:flex md:items-center md:justify-between w-full border-b border-gray-400 pb-4">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                                Select a session
                            </h2>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                Pick a session from the history and see the information associated.
                            </p>
                        </div>
                    </div>
                </div>}
                {activeSession && !loading && <div className="w-full">
                    <div className="md:flex md:items-center md:justify-between w-full border-b border-gray-400 pb-4">
                        <div className="min-w-0 flex-1">
                            <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
                                Notes for {activeSession.name == "" ? "Session" : activeSession.name}
                            </h2>
                            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                                Manage the captured notes for recorded sessions including the raw recording, transcript and generated notes.</p>
                        </div>
                        {/* <div className="mt-4 flex md:ml-4 md:mt-0">
                            <button
                            type="button"
                            className="ml-3 inline-flex gap-x-2 items-center rounded-md bg-main-lighter px-3 py-2 text-sm font-semibold text-main-text shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                            >
                                Share Notes
                                <ShareIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                            </button>
                        </div> */}
                    </div>
                    <div className="w-full px-4 pt-10">
                        <div className="flex flex-col gap-y-4 mx-auto w-full max-w-full rounded-2xl bg-white p-2">
                            {activeRecording && <Disclosure as="div">
                                <Disclosure.Button className={`flex w-full justify-between rounded-lg bg-accent px-4 py-2 text-left text-lg font-medium text-gray-900 hover:bg-selected focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}>
                                    <span>Recording</span>
                                    <audio controls src={activeRecording} className="w-full my-4 fill-green-600">
                                        Your browser does not support the audio element.
                                    </audio>
                                </Disclosure.Button>
                            </Disclosure>}
                            {activeTranscript && <Disclosure as="div">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className={`flex w-full justify-between ${open ? "rounded-t-lg" : "rounded-lg"} bg-accent px-4 py-2 text-left text-lg font-medium text-gray-900 hover:bg-selected focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}>
                                        <span>Transcript</span>
                                            <ChevronDownIcon
                                            className={`${
                                                open ? 'rotate-180 transform' : ''
                                            } h-5 w-5 text-blue-500`}
                                            />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-6 pb-6 pt-6 text-sm text-gray-500 border border-gray-300 rounded-b-lg">
                                        <ReactQuill
                                            theme='snow'
                                            placeholder='Add notes'
                                            className='block w-full h-80 rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                            value={SpacingText(activeTranscript)}
                                            readOnly
                                            modules={{toolbar: false}}
                                        />
                                    </Disclosure.Panel>
                                </>
                            )}
                            </Disclosure>}
                            {activeManualNotes && <Disclosure as="div">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className={`flex w-full justify-between ${open ? "rounded-t-lg" : "rounded-lg"} bg-accent px-4 py-2 text-left text-lg font-medium text-gray-900 hover:bg-selected focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}>
                                        <span>Additional Notes</span>
                                            <ChevronDownIcon
                                            className={`${
                                                open ? 'rotate-180 transform' : ''
                                            } h-5 w-5 text-blue-500`}
                                            />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-6 pb-6 pt-6 text-sm text-gray-500 border border-gray-300 rounded-b-lg">
                                        <ReactQuill
                                            theme='snow'
                                            placeholder='Add notes'
                                            className='block w-full h-80 rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                            value={SpacingText(activeManualNotes)}
                                            modules={{toolbar: false}}
                                            readOnly
                                        />
                                    </Disclosure.Panel>
                                </>
                            )}
                            </Disclosure>}
                            {activeNotes && <Disclosure as="div">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className={`flex w-full justify-between ${open ? "rounded-t-lg" : "rounded-lg"} bg-accent px-4 py-2 text-left text-lg font-medium text-gray-900 hover:bg-selected focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}>
                                        <span>SOAP Notes</span>
                                        <ChevronDownIcon
                                        className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-blue-500`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-6 pb-6 pt-6 text-sm text-gray-500 border border-gray-300 rounded-b-lg">
                                        {notesLoading && <Loading text={notesLoading}/>}
                                        {!notesLoading && <>
                                            <div className="flex flex-row gap-x-4 justify-end pb-4">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-1.5 text-sm font-semibold text-accent-button-text shadow-sm hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                    onClick={() => saveNotes()}
                                                >
                                                    Save
                                                    <CloudArrowUpIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                                </button>
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center gap-x-2 rounded-md bg-accent-button px-3.5 py-1.5 text-sm font-semibold text-accent-button-text shadow-sm hover:bg-accent-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                    
                                                    onClick={() => copyToClipboard(activeNotes)}
                                                >
                                                    {copySuccess ? "Copied" : "Copy"}
                                                    {copySuccess ? <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" /> :<ClipboardDocumentIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                                                </button>
                                            </div>
                                            <ReactQuill
                                                theme='snow'
                                                placeholder='Add notes'
                                                className='block w-full h-80 rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none'
                                                value={displayActiveNotes}
                                                modules={{toolbar: false}}
                                                onChange={(value, d, s, editor) => {
                                                    setDisplayActiveNotes(value)
                                                    setActiveNotes(editor.getText())
                                                }}
                                            />
                                        </>}
                                    </Disclosure.Panel>
                                </>
                            )}
                            </Disclosure>}
                            {!activeNotes && <div
                                className="mt-2 relative flex md:flex-row flex-col justify-center items-center w-full rounded-lg border-2 border-dashed border-gray-400 p-4 text-center hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 gap-x-4 gap-y-6"
                                >
                                    {/* <div className="flex flex-row gap-x-4 justify-center items-center">
                                        <DocumentTextIcon className="h-8 w-8 text-blue-600"/>
                                        <span className="block text-md font-semibold text-main-text-darker">SOAP notes</span>
                                    </div> */}
                                    {templates && <div className="shrink-0 sm:w-60 w-full h-12 relative">
                                        <label
                                            htmlFor="name"
                                            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker z-[1]"
                                        >
                                            Template
                                        </label>
                                        <Listbox value={template} onChange={setTemplate} defaultValue={preBuiltTemplate[0]}>
                                            <div className="relative z-[0]">
                                            <Listbox.Button className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm h-12">
                                                <span className="block truncate">{template?.name}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                                </span>
                                            </Listbox.Button>
                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-gray-300 focus:outline-none sm:text-sm cursor-pointer">
                                                {templates.map((template, templateIdx) => (
                                                    <Listbox.Option
                                                    key={templateIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 cursor-pointer ${
                                                        active ? 'bg-accent text-main-text-darker' : 'text-main-text-darker'
                                                        }`
                                                    }
                                                    value={template}
                                                    >
                                                    {({ selected }) => (
                                                        <>
                                                        <span
                                                            className={`block truncate ${
                                                            selected ? 'font-medium' : 'font-normal'
                                                            }`}
                                                        >
                                                            {template.name}
                                                        </span>
                                                        {selected ? (
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                        ) : null}
                                                        </>
                                                    )}
                                                    </Listbox.Option>
                                                ))}
                                                </Listbox.Options>
                                            </Transition>
                                            </div>
                                        </Listbox>
                                    </div>}
                                    <button
                                        type="button"
                                        className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12"
                                        onClick={processTranscript}
                                    >
                                        Generate notes
                                        <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                                    </button>
                            </div>}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}