import config from './config.json'
const url = config.ENDPOINT

export async function processRecording(session:string, name:string, jwt:string, manual_notes:boolean, consent:boolean) {
    const endpoint = url + '/v2/process_recording';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session, name, manual_notes, consent })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function generateSoapNotes(session:string, name:string, template_id:string, transcript:boolean, manual_notes:boolean, jwt:string) {
    const endpoint = url + '/v2/soap_notes';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session, name, transcript, manual_notes, template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function generateSOAPFollowup(session:string, msg:string, jwt:string) {
    const endpoint = url + '/v1/soap_notes_followup';
    console.log(msg)
    console.log(JSON.stringify({ session, msg }))
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session, msg })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export interface message{
    role:string,
    message:string
}

export async function generateAssisstant(messages:Array<message>, jwt:string) {
    const endpoint = url + '/v1/assistant';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ messages })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}


// Function to fetch all sessions
export async function fetchSessions(jwt:string) {
    return fetch(url + '/v1/sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        console.error('Error fetching sessions:', error);
    });
}

// Function to fetch a specific session by ID
export async function fetchSessionById(sessionId:string, jwt:string) {
    return fetch(url + `/v1/session/${sessionId}`, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.error('Error fetching session:', error);
    });
}

// Function to fetch all sessions
export async function fetchTemplates(jwt:string) {
    return fetch(url + '/v1/templates', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        console.error('Error fetching sessions:', error);
    });
}

// Function to fetch a specific session by ID
export async function fetchTempalteById(templateId:string, jwt:string) {
    return fetch(url + `/v1/template/${templateId}`, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        console.error('Error fetching session:', error);
    });
}

export async function createTemplate(template_id:string, name:string, jwt:string) {
    const endpoint = url + '/v1/template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ name, template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}