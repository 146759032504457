import { useAuth } from "./AuthProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import config from '../config.json'

const AuthRoute = () => {
  const { user, session } = useAuth();
  const test = false
  const auth = config.AUTH
  const location = useLocation();
  return user || test ? (
    <Outlet />
  ) : (
    <Navigate to={"/login"} replace state={{ path: location.pathname }} />
  );
};

export default AuthRoute;