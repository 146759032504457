import cat1 from './cat1.png'
import cat2 from './cat2.png'
import dog1 from './dog1.png'
import horse1 from './horse1.png'
import giraffe1 from './giraffe1.png'
import robot from './Robot.png'

export const profilePics = {
    cat1,
    cat2,
    dog1,
    horse1,
    giraffe1,
    robot
}